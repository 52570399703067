<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Default {{str_title(default_earning_deductions.type)}} </h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'default-earning-deductions'}">
                        <span>Default Earning Deductions</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create Default {{str_title(default_earning_deductions.type)}}</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:keyup.enter="CreateDefaultEarningDeductions($event)">

                    <div class="form-group row">
                        <label class="col-md-3">
                            {{str_title(default_earning_deductions.type)}} Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="nameHelpInline"
                                v-model="default_earning_deductions.name"
                                @change="textTitleCase($event, 'name')"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Formula Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select
                                value-field="item"
                                text-field="name"
                                @input="onChangeName"
                                v-model="default_earning_deductions.formula"
                                :options="payroll_formulas"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>

                    <div class="form-group row" v-if="payroll_formula">
                        <label class="col-md-3">
                            Formula
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(payroll_formula.conditions)[JSON.parse(payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(payroll_formula.conditions).slice(0, JSON.parse(payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(value, slug) in default_earning_deductions.values" :key="slug" class="form-group row">
                        <label class="col-md-3">
                            {{ str_title(slug) }}
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="number"
                                class="form-control"
                                aria-describedby="typeHelpInline"
                                v-model="default_earning_deductions.values[slug]"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                   
                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="CreateDefaultEarningDeductions()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="$router.go(-1)"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: false,
            default_earning_deductions: {
                name: '',
                formula: '',
                type: '',
                values: ''
            },
            payroll_formulas_list:[],
            payroll_formula: null,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            payroll_formulas:[
                { item: '', name: "Select Payroll Formula" },
            ],
            default_formula_variables:"",
        };
    },
    created() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
    },
    mounted() {
        this.default_earning_deductions.type=this.$route.params.type;
        this.unusedFormulaDefaultEarningDeduction();
        this.default_formula_variables = this.$store.getters.getAppSetting("payroll", "pre_calculated_variables");
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.default_earning_deductions[key] = this.strTitleCase(event.target.value);
        },

        onChangeName(value) {
            if (value=='') {
                this.default_earning_deductions.values = {};
                this.payroll_formula = null;
                return;
            }
            var query = this.getAuthHeaders();
            query['params'].id=value;
            this.axios.get(this.$api.get_payroll_formulas, query).then(response => {
                let result = response.data.data;
                this.payroll_formula = result;
                this.default_earning_deductions.values = {};
                var conditions = JSON.parse(result.conditions);
                conditions.forEach(condition => {
                    if (('condition' in condition)) {
                        let condition_variables = condition['condition'].match(/\[(.*?)\]/gm);
                        condition_variables.forEach( (variable) => {
                            var inputs = variable.replace(/\[|\]/g,'');
                            if(!this.default_formula_variables.includes(inputs)) {
                                this.default_earning_deductions.values[inputs] = ""; 
                            }
                        })
                    }

                    let formula_variables = condition['formula'].match(/\[(.*?)\]/gm);
                    formula_variables.forEach( (variable) => {
                        var inputs = variable.replace(/\[|\]/g,'');
                        if(!this.default_formula_variables.includes(inputs)) {
                            this.default_earning_deductions.values[inputs] = ""; 
                        }
                    })
                });
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        getPayrollFormulas() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_payroll_formulas, query).then(response => {               
                response.data.data.forEach(payroll_formulas => {
                    this.default_formula_variables.push(
                        payroll_formulas.slug
                    );
                });
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        unusedFormulaDefaultEarningDeduction: function() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            query['params']={'applicable_for':'default-'+this.$route.params.type+'s'};
            this.axios.get(this.$api.unused_formula_default_earning_deduction, query)
                .then(response => {
                    response.data.data.forEach( payroll_formulas => {
                        this.payroll_formulas.push({
                            item: payroll_formulas.id,
                            name: payroll_formulas.name
                        });
                    });
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.getPayrollFormulas();   

                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        CreateDefaultEarningDeductions: function() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            let params = {
                name: this.default_earning_deductions.name,
                type: this.default_earning_deductions.type,
                values: this.default_earning_deductions.values
            };
            
            if (this.payroll_formula) {
                params['formula_id'] = this.payroll_formula.id;
            }

            params = this.deleteEmptyKeys(params);
            query["params"] = {};
            this.axios.post(this.$api.create_default_earning_deduction, params, query)
                .then(response => {
                    if (response.data.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({ name: "default-earning-deductions" });
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";
</style>
